import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=1fd26448&scoped=true&"
import script from "./Testimonials.vue?vue&type=script&lang=ts&"
export * from "./Testimonials.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd26448",
  null
  
)

export default component.exports